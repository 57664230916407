<template>
  <div class="row mx-0 justify-content-center text-center p-3 position-relative">
    <div class="col-12 green-text-dark font12 bold mb-1">
      PAYMENT
      <span v-if="paymentMethod === 'DEBIT_ORDER'"> DETAILS</span>
      <span v-else> METHOD</span>
    </div>
    <div v-if="bankAccountDetails && paymentMethod === 'DEBIT_ORDER'" class="col-12 green-text-dark" >
      <span v-if="bankAccountDetails.bankName">{{bankAccountDetails.bankName}}</span>
      <span v-if="bankAccountDetails.branchCode"> - {{bankAccountDetails.branchCode}}</span>
      <span v-if="bankAccountDetails.accountNumber"> - {{bankAccountDetails.accountNumber}}</span>
    </div>
    <div v-else-if="paymentMethod === 'CREDIT_CARD'" class="col-12 green-text-dark" >
      <span>Card (Online Setup)</span>
    </div>
    <div v-if="paymentMethod === 'DEBIT_ORDER'" class="col-12 font12" >
      <span v-if="paymentFrequencyDetails.debitOrderDay">{{dayDisplay}} of Every Month</span>
      <span v-if="paymentFrequencyDetails.debitOrderMonth"> - Starting {{monthDisplay}} {{yearDisplay}}</span>
    </div>
    <div class="col-12 font12" v-else>
      Starting {{dateDisplay}}
    </div>
    <div class="onhover" :class="{show: isHover === true }" @mouseover="isHover = true" @mouseleave="isHover = false">
      <div class="row m-0 h-100 justify-content-center align-items-center">
        <div class="col-auto">
          <Button color="darkgrey" size="small" btnText="EDIT" icon="arrow" @buttonClicked="goTo">
            <IconEdit size="size16" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  name: 'Review Donation Payment Summary',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  props: ['paymentMethod', 'paymentFrequencyDetails', 'bankAccountDetails'],
  emits: ['goTo'],
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    dateDisplay () {
      return DateTime.fromMillis(this.paymentFrequencyDetails.startDate).toFormat('dd LLLL yyyy')
    },
    monthDisplay () {
      const theMonth = this.paymentFrequencyDetails.debitOrderMonth.toString()
      return DateTime.fromFormat(theMonth, 'M').toFormat('MMMM')
    },
    yearDisplay () {
      return DateTime.fromMillis(this.paymentFrequencyDetails.startDate).toFormat('yyyy')
    },
    dayDisplay () {
      const num = parseInt(this.paymentFrequencyDetails.debitOrderDay)
      const lastDigit = num % 10
      const lastTwoDigits = num % 100

      return num + (
        // 11-13 take 'th'
        (lastTwoDigits > 9 && lastTwoDigits < 14) ? 'th'
          : lastDigit === 1 ? 'st'
            : lastDigit === 2 ? 'nd'
              : lastDigit === 3 ? 'rd' : 'th'
      )
    }
  },
  methods: {
    goTo () {
      this.$emit('goTo')
    }
  }
}
</script>
<style scoped>
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
</style>
